import axios from '@/utils/http';

//通过手机号获取积分列表
export function receiveListByPhone(params) {
  return axios.get('/wlynBatchIntegralInfo/selectListByPhone', {
    params
  });
}
//web根据手机号获取折扣券领取记录列表
export function voucherListByPhone(params) {
  return axios.get('/wlynVouchersUserRelation/selectListByPhone', {
    params
  });
}
//web根据手机号获取积分提现列表
export function drawalListByPhone(params) {
  return axios.post('/wlynIntegralWithdrawal/selectListByPhone', 
    params
  );
}

//获取wx用户列表
export function selectWxUserInfoList(params) {
  return axios.get('/wlynUser/selectUserInfoList', {
    params
  });
}
//获取wx用户详情
export function getWxUserInfo(params) {
  return axios.get('/wlynUser/getUserInfo', {
    params
  });
}

//获取用户列表
export function selectWlynUserPage(params) {
  return axios.get('/wlynUser/selectWlynUserPage', {
    params
  });
}
//修改用户详情
export function updateWlynUser(params) {
  return axios.post('/wlynUser/updateWlynUser', params);
}
//修改银行卡
export function updateBankCard(params) {
  return axios.post('/wlynBankCard/updateBankCard', params);
}
//导入客户
export function confirmImport(params) {
  return axios.post('/wlynUser/confirmImport', params);
}

//是否开启修改(0.否 1.是)
export function updateByUserIsUpdate(params) {
  return axios.post('/wlynUser/updateByUserIsUpdate', params);
}

//查询充值记录分页列表
export function getWlynProductRechargePage(params) {
  return axios.post('/WlynProductRecharge/getWlynProductRechargePage', params);
}
//查询消费记录分页列表
export function getConsumptionRecordsPage(params) {
  return axios.post('/WlynProductRecharge/getConsumptionRecordsPage', params);
}


//修改用户名称
export function updateUserNameByPhone(params) {
  return axios.get('/wlynUser/updateUserNameByPhone', {
    params
  });
}

//修改用户编号
export function updateUserNumberByPhone(params) {
  return axios.get('/wlynUser/updateUserNumberByPhone', {
    params
  });
}
//修改用户手机号
export function updateUserPhone(params) {
  return axios.get('/wlynUser/updateUserPhone', {
    params
  });
}


//新增用户操作记录
export function addUserOperation(params) {
  return axios.post('/wlynUserOperation/addUserOperation', params);
}
//根据id删除用户操作记录
export function deleteUserOperation(params) {
  return axios.get('/wlynUserOperation/deleteUserOperation', {
    params
  });
}
//根据手机号查询用户操作记录
export function getUserOperationByPhone(params) {
  return axios.get('/wlynUserOperation/getUserOperationByPhone', {
    params
  });
}
//是否开启超龄提现(0.否 1.是)
export function updateByUserIsOverage(params) {
  return axios.post('/wlynUser/updateByUserIsOverage', params);
}
//是否开启隐藏(0.否 1.是)
export function updateByUserIsHide(params) {
  return axios.post('/wlynUser/updateByUserIsHide', params);
}


//统计用户活跃数据
export function getUserOnLine(params) {
  return axios.get('/wxUser/getUserOnLine', {
    params
  });
}