import axios from "axios";
import router from "../router";
import { getCookies } from "@/utils/utils";
import store from "../store";
import { Message } from "element-ui";
export const baseURL = process.env.VUE_APP_BASEURL;
axios.defaults.timeout =
  process.env.VUE_APP_TYPE === "production" ? 60000 : 600000;
axios.defaults.baseURL = baseURL;

//http request 拦截器
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json",
    };
    if (getCookies("x-token")) {
      config.headers["x-token"] = getCookies("x-token");
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

function logOut() {
  Message.error({
    title: "错误",
    message: "已下线,请重新登录",
    duration: 3500,
  });
  router.push({
    path: "/login",
  });
  store.dispatch("removeCookiesStorage");
}
//http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let errorStatus = "Error: Request failed with status code 401" == error;
    if (errorStatus) {
      logOut();
    }
    if (JSON.stringify(error).indexOf("Error: timeout") != -1) {
      Message.error({
        title: "错误",
        message: "连接超时,请重试",
        duration: 2500,
      });
    }
    console.log("错误信息", error);
    return Promise.reject(error);
  }
);

export default axios;
/**
 * 封装文件下载方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function downloadFile(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseURL + url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseURL + url, {
        params: params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(baseURL + url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function longPost(url, data = {}, timeout) {
  return new Promise((resolve, reject) => {
    axios.post(baseURL + url, data, { timeout: timeout }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(baseURL + url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装deletes请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function deletes(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(baseURL + url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(baseURL + url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
