<template>
  <el-form-item :label="label" v-if="(isCheck && list.length>1) || !isCheck" :required="required">
    <el-select v-model="value2" placeholder="请选择" style="width:100%" @change="change" filterable>
      <el-option label="全部" value="" v-show="showAll"></el-option>
      <el-option v-for="item in list" :key="item.id" :label="item.organizationName" :value="item.id"></el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import { organizationList } from '@/api/setting'
import { getCookies } from '@/utils/utils'
export default {
  name: 'OrganizationSelect',
  props: {
    value: Number | String,
    label: {
      type: String,
      default: '提交机构'
    },
    isCheck: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    }

  },
  data() {
    return {
      list: [],
      value2: '',
      orgId: ''
    }
  },
  created() {
    this.orgId = getCookies('organizationId')
    organizationList({ orgId: this.orgId, pageNum: 1, pageSize: 100000, }).then(res => {
      this.value2 = Number(this.value) || ''
      console.log(res, this.value2, this.value)
      this.list = res.data.records

      //实现需求：显示全部并且有多个机构，则默认全部， 显示全部并且只有一个机构时，默认当前登录机构
      if (this.showAll && this.list.length > 1) {
        this.value2 = ''
        this.change(1)
      } else if (this.showAll && this.list.length <= 1) {
        this.value2 = this.orgId
        this.change(1)
      }
    })
  },
  methods: {
    change(type) {
      console.log(111, this.value2)
      this.$emit('input', this.value2)
      if (type == 1) {
        this.$emit('reload')
      }
    }
  }
}
</script>

<style>
</style>