/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-03 17:29:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\api\setting.js
 */
import {
  post,
  fetch,
  patch,
  put,
  deletes
} from '@/utils/http'
import axios from '@/utils/http'

// ----------------机构管理
//获取分支机构详情
export function organizationDetail(params) {
  return fetch('/organization/getOrganizationById/' + params);
}

//编辑分支机构
export function organizationEdit(params) {
  return post('/organization/editorOrganization', params);
}

//添加分支机构
export function organizationAdd(params) {
  return post('/organization/addOrganization', params);
}

//分支机构列表
export function organizationList(params) {
  return fetch('/organization/getOrganizationList', params);
}
//分支机构列表
export function delOrganizationById(params) {
  return axios.delete(`/organization/delOrganizationById/${params}`);
}


//是否禁用人员
export function isEnable(params) {
  return axios.put('/sysMain/isEnable', params);
}

//指定分支机构审批人
export function addApproval(params) {
  return post('/organization/addApproval', params);
}
// 获取账户Tree 列表
export function getOrganizationTree(params) {
  return fetch('/organization/getOrganizationTree/' + params);
}

// -------------折扣券设置

// 添加 or 编辑 折扣券
export function addOrEditorVouchers(params) {
  return post('/wlynVouchers/addOrEditorVouchers', params);
}
// 删除折扣券
export function delVouchers(params) {
  return deletes('/wlynVouchers/delVouchers/' + params);
}
// 单个或批量新增折扣券
export function createVouchers(params) {
  return post('/wlynVouchers/createVouchers', params);
}

// 获取折扣券详情
export function getVouchersInfo(params) {
  return fetch('/wlynVouchers/getVouchersInfo', params);
}

// 获取折扣券列表
export function getVouchersList(params) {
  return fetch('/wlynVouchers/getVouchersList', params);
}

// 禁用折扣券
export function isDisable(params) {
  return axios.post('/wlynVouchers/isDisable', params);
}

// -------------人员管理

//查询指定机构下所有用户信息
export function querySysUserListById(params) {
  return fetch('/sysMain/querySysUserListById', params);
}

//根据id查询用户信息
export function querySysUserById(params) {
  return fetch('/sysMain/querySysUserById', params);
}

//添加用户
export function addSysUser(params) {
  return post('/sysMain/addSysUser', params);
}
//编辑用户
export function editSysUser(params) {
  return post('/sysMain/editSysUser', params);
}



// -------------规则配置

// 获取规则详情
export function queryRule(params) {
  return fetch('/wlynConfiguring/queryRule', params);
}

// 编辑规则
export function editConfiguring(params) {
  return post('/wlynConfiguring/editConfiguring', params);
}
// -------------- 积分配置

// 获取省份列表
export function getProvincesList() {
  return fetch('/wlynRegion/getProvincesList');
}
// 根据省份编码获取市区列表
export function getRegionByRegionCode(params) {
  return axios.get('/wlynRegion/getRegionByRegionCode', {
    params
  });
}
// 根据市区编码获取市区列表
export function getRegionInfo(params) {
  return axios.get('/wlynRegion/getRegionInfo', {
    params
  });
}

// 获取区域树形结构
export function getRegionTree(params) {
  return fetch('/wlynRegion/getRegionTree', params);
}

// 根据省份获取市区积分配置列表
export function getCityConfigList(params) {
  return fetch('/wlynIntegralConfig/getCityConfigList', params);
}

// 获取市区积分配置详情
export function getCityConfigInfo(params) {
  return fetch('/wlynIntegralConfig/getCityConfigInfo/' + params);
}

// 添加更新市区积分配置
export function addOrUpdateCityConfig(params) {
  return post('/wlynIntegralConfig/addOrUpdateCityConfig', params);
}

// -------------- menu 权限菜单配置

// 获取全部权限菜单列表
export function getMenuList(params) {
  return fetch('/sysMenu/getMenuList', params);
}
// 根据角色获取菜单
export function getRoleMenu(params) {
  return fetch('/sysMenu/getRoleMenu/' + params);
}
// 设置或更新角色菜单
export function setRoleMenu(params) {
  return post('/sysMenu/setRoleMenu', params);
}

//获取全部角色-添加用户时角色选择
export function getRoleAll(params) {
  return fetch('/wlynRole/getRoleAll', params);
}
// 删除角色
export function deleteRole(params) {
  return fetch('/wlynRole/deleteRole', params);
}
// 新增角色
export function createRole(params) {
  return post('/wlynRole/createRole', params);
}
// 修改角色
export function updateRole(params) {
  return post('/wlynRole/updateRole', params);
}

//  -------------------------------

// 获取套餐详情
export function getPackageInfo(params) {
  return fetch('/wlynVouchersPackage/getPackageInfo/' + params);
}

// 获取套餐审批详情
export function getPackageApprovalInfo(params) {
  return fetch('/wlynVouchersPackageIssue/getPackageApprovalInfo/' + params);
}

// 删除折扣券
export function delPackage(params) {
  return deletes('/wlynVouchersPackage/delPackage/' + params);
}

// 获取套餐list
export function getPackageList(params) {
  return fetch('/wlynVouchersPackage/getPackageList', params);
}

// 添加or编辑卡券套餐
export function addOrEditorVouchersPackage(params) {
  return post('/wlynVouchersPackage/addOrEditorVouchersPackage', params);
}


// 单个或多个卡券套餐发放
export function vouchersPackageIssue(params) {
  return post('/wlynVouchersPackageIssue/vouchersPackageIssue', params);
}

// 按客户展示发放记录
export function customerIssueRecord(params) {
  return fetch('/wlynVouchersPackageIssue/customerIssueRecord', params);
}


// 查询劵赠送配置分页列表
export function getVouchersGiveConfigPage(params) {
  return axios.get('/wlynVouchersGiveConfig/getVouchersGiveConfigPage', {
    params
  });
}
// 查询劵赠送配置列表
export function getVouchersGiveConfigList(params) {
  return axios.get('/wlynVouchersGiveConfig/getVouchersGiveConfigList', {
    params
  });
}
// 根据id删除劵赠送配置
export function deleteVouchersGiveConfig(params) {
  return axios.get('/wlynVouchersGiveConfig/deleteVouchersGiveConfig', {
    params
  });
}
// 更新省区劵赠送配置
export function addOrUpdateVouchersGiveConfig(params) {
  return axios.post('/wlynVouchersGiveConfig/addOrUpdateVouchersGiveConfig', params);
}
// 增加商品
export function addGoosShop(params) {
  return axios.post('/tGood/addGoosShop', params);
}
// 删除商品
export function delGoosShop(params) {
  return axios.get('/tGood/delGoosShop', {params});
}
// 修改商品
export function updateGoosShop(params) {
  return axios.post('/tGood/updateGoosShop', params);
}
// 商品列表
export function getGoosListPage(params) {
  return axios.post('/tGood/getGoosListPage', params);
}
// 商品详情
export function getGoosDetailByWeb(params) {
  return axios.get('/tGood/getGoosDetailByWeb', {params});
}
// 订单列表
export function getShoppingOrderPage(params) {
  return axios.post('/tGood/getShoppingOrderPage', params);
}
// 订单详情
export function getShoppingOrderDetali(params) {
  return axios.get('/tGood/getShoppingOrderDetali', {params});
}