<template>
  <div>
    <el-form label-width="90px" inline size="mini">
      <el-form-item label="搜索：">
        <el-input style="width:200px;" placeholder="请输入名称/手机号/卡号/身份证/工号" v-model="search.search" @keyup.native.enter="handleCurrentChange(1)"></el-input>
      </el-form-item>
      <el-form-item label="积分：">
        <el-select style="width:150px;" v-model="search.isIntegral" placeholder="请选择">
          <el-option v-for="item in integral" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="券：">
        <el-select style="width:150px;" v-model="search.isVouchers" placeholder="请选择">
          <el-option v-for="item in coupons" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        <el-button type="primary" icon="el-icon-folder-opened" v-show="false" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table stripe :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto" height="calc(100vh - 230px)">
      <el-table-column align="center" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="submitOrgName" align="center" label="提交机构" width="130" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" align="center" label="姓名"></el-table-column>
      <el-table-column prop="phone" align="center" label="绑定手机号"></el-table-column>
      <!-- <el-table-column prop="number" align="center" label="编号"></el-table-column> -->
      <el-table-column prop="totalIntegral" align="center" label="总积分"></el-table-column>
      <el-table-column prop="usedIntegral" align="center" label="已使用积分"></el-table-column>
      <el-table-column prop="surplusIntegral" align="center" label="剩余积分"></el-table-column>
      <el-table-column prop="totalVouchers" align="center" label="券"></el-table-column>
      <el-table-column align="center" label="提现姓名" v-if="!hidden">
        <template slot-scope="scope">
          <el-button type="text" @click="checkboxChange(scope.row)" :disabled="scope.row.userType==1">{{scope.row.isUpdate==1?'可修改':'不可修改'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="超龄可提交" v-if="!hidden">
        <template slot-scope="scope">
          <el-button type="text" @click="isSubmitChange(scope.row)" :disabled="scope.row.userType==1">{{scope.row.isOverage==1?'可提交':'不可提交'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="注销状态" v-if="!hidden">
        <template slot-scope="scope">
          <el-button type="text" @click="isHideChange(scope.row)" :disabled="scope.row.userType==1">{{scope.row.isHide==1?'已注销':'已激活'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-view" style="color:#333" type="text" @click="showDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import OrganizationSelect from '@/components/OrganizationSelect.vue'
import { selectWxUserInfoList, updateByUserIsUpdate, updateByUserIsOverage, updateByUserIsHide } from '@/api/customer'
import { exportCommon, getCookies } from '@/utils/utils'
export default {
  name: 'customerIndex',
  components: { OrganizationSelect },
  data() {
    return {
      search: {
        submitOrg: '',
        pageNum: 1,
        pageSize: 10,
        isIntegral: '',
        isVouchers: '',
        search: ''
      },
      total: 0,
      list: [],
      integral: [
        { label: '全部', value: '' },
        { label: '有', value: '0' },
        { label: '无', value: '1' },
      ],
      coupons: [
        { label: '全部', value: '' },
        { label: '有', value: '0' },
        { label: '有且未使用', value: '1' },
      ],
      hidden: false,//隐藏详情操作按钮
    }
  },
  created() {
    this.hidden = this.$route.meta.hidden

    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    let search = JSON.parse(sessionStorage.getItem('search')) || {}
    Object.assign(this.search, search)
    sessionStorage.removeItem('search')
    // this.getList()
  },
  methods: {
    getList() {
      let param = Object.assign({}, this.search)
      selectWxUserInfoList(param).then(res => {
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getList()
    },
    showDetail(item) {
      let search = JSON.stringify(this.search)
      this.$router.push(`/customer/detail?phone=${item.phone}&name=${item.name}&search=${search}&hidden=${this.hidden}&userType=${item.userType}`)
    },

    checkboxChange(row) {
      let data = Object.assign({}, row)
      this.$confirm(data.isUpdate ? '是否修改为不可修改提现姓名?' : '是否修改为可修改提现姓名?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        data.isUpdate = data.isUpdate ? 0 : 1
        updateByUserIsUpdate(data).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.getList()
          } else {
            this.$message.error(res.msg || '修改失败')
          }
        })
      }).catch(() => {
      })
    },
    isHideChange(row) {
      let data = Object.assign({}, row)
      this.$confirm(data.isHide ? '是否修改为激活?' : '是否修改为注销?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        data.isHide = data.isHide ? 0 : 1
        updateByUserIsHide(data).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.getList()
          } else {
            this.$message.error(res.msg || '修改失败')
          }
        })
      }).catch(() => {
      })
    },
    isSubmitChange(row) {
      let data = Object.assign({}, row)
      this.$confirm(data.isOverage ? '是否修改为超龄不可提交提现?' : '是否修改为超龄可提交提现?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        data.isOverage = data.isOverage ? 0 : 1
        updateByUserIsOverage(data).then(res => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.getList()
          } else {
            this.$message.error(res.msg || '修改失败')
          }
        })
      }).catch(() => {
      })

    },
    exportList() {
      let param = Object.assign({}, this.search)
      delete param.pageNum
      delete param.pageSize
      param.submitOrg = param.submitOrg || getCookies('organizationId')
      exportCommon('/wlynUser/exportExcel', param)
    }
  }
}
</script>